/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'

import Heading from '~/components/IntlComponents/Heading'
import Stamp from '~/assets/images/icons/stampGooder.svg'

const Header = ({ hero, color, background }) => {
  return (
    <Flex
      sx={{
        Zindex: 1,
        position: 'relative',
        margin: 'auto',
        justifyContent: 'center',
        flexDirection: ['column', null, 'row'],
        pt: '91px',
        backgroundColor: background,
        px: ['30px', null, '52px'],
        pb: ['58px', null, '104px'],
        color: color,
      }}
    >
      <Box>
        <Heading
          as="h1"
          variant="h2"
          sx={{
            color: 'inherit',
            fontSize: 'clamp(48px, 12vw, 151px) !important',
            textAlign: ['center', null, 'left'],
            lineHeight: 'clamp(48px, 12vw, 133px) !important',
            br: { display: ['none', null, 'block'] },
            mb: '30px',
          }}
          dangerouslySetInnerHTML={{ __html: hero.heading?.heading }}
        />

        <Box
          sx={{
            color: 'inherit',
            textAlign: ['center', null, 'left'],
            fontSize: ['18px', null, '20px'],
            lineHeight: ['22px', null, '24px'],
            maxWidth: ['100%', null, 'clamp(315px, 60vw, 860px) !important'],
          }}
          dangerouslySetInnerHTML={{
            __html: hero.subheading?.subheading,
          }}
        />
      </Box>
      <Stamp
        sx={{
          position: ['absolute', null, 'relative'],
          right: 0,
          bottom: ['-100px', null, 0],
          Zindex: 1,
          background: background,
          borderRadius: '50%',
          padding: '10px',
          maxWidth: ['128px', null, '100%'],
          maxHeight: ['128px', null, '100%'],
        }}
      />
    </Flex>
  )
}

export default Header
