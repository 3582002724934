/**
 *
 * @param {*} imageOrVideo Image or video content type with file contentType
 * @param {{VideoComponent: (item: any) => React.ReactElement, ImageComponent: (item: any) => React.ReactElement }} components video and image callback with component, only the correct component for given imageOrVideo contenttype will render
 * @returns
 */

export function renderImageOrVideo(
  imageOrVideo,
  { VideoComponent = null, ImageComponent = null },
) {
  if (!imageOrVideo?.file?.contentType) {
    console.error('No type provided')
    return null
  }
  const {
    file: { contentType },
  } = imageOrVideo
  if (/image\//i.test(contentType)) {
    return ImageComponent(imageOrVideo)
  }
  if (/video\//i.test(contentType)) {
    return VideoComponent(imageOrVideo)
  }
  return null
}
