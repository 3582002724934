import { graphql } from 'gatsby'
import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContentAboutPage from '~/components/Content/About'

const About = ({ data }) => {
  const { page, communityGallery, founderGallery } = data

  return page ? (
    <Layout>
      <Metadata title={page.name} />
      <ContentAboutPage
        page={page}
        founderGallery={founderGallery}
        communityGallery={communityGallery}
      />
    </Layout>
  ) : null
}

export default About

export const query = graphql`
  query AboutPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "our-story" }
      node_locale: { eq: $locale }
    ) {
      name
      ...ContentHeroFragment
      ...ContentSectionFragment
    }
    founderGallery: contentfulImageGallery(
      slug: { eq: "about-founder-gallery" }
    ) {
      name
      title
      images {
        ...ContentImageForGallery
      }
    }
    communityGallery: contentfulImageGallery(
      slug: { eq: "about-community-gallery" }
    ) {
      name
      title
      images {
        ...ContentImageForGallery
      }
    }
  }
`
